@use 'variables' as v;

// Header
.header {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    // Desktop layout
    @media (min-width: v.$breakpoint-lg) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      text-align: left;
      height: 70vh;
    }

    // IPad Pro layout
    @media (width: 1024px) and (height: 1366px) {
      margin-top: 10rem;
      height: 50vh;
      line-height: 1.7;
    }
  }

  &__img {
    max-width: 100vw;
    padding-bottom: 5rem;
    overflow: hidden;

    img {
      width: 55rem;
    }

    // Desktop layout
    @media (min-width: v.$breakpoint-lg) {
      img {
        width: 75rem;
      }
      padding-bottom: 0;
      position: absolute;
      right: -20%;
      top: 35%;
      transform: translateY(-50%);

      @media (max-width: 1100px) {
        right: -25%;
      }

      // IPad Pro layout
      @media (width: 1024px) and (height: 1366px) {
        width: 50vw;
        right: 0%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 13rem;
    position: relative;
    top: -15%;

    // Desktop layout
    @media (min-width: v.$breakpoint-lg) {
      padding-bottom: 0;
      width: 70%;
      align-items: flex-start;
      margin-right: 5rem;
    }

    .l-heading {
      @media (max-width: v.$breakpoint-lg) {
        max-width: 35rem;
      }
    }

    .text-lead {
      padding: 0.5rem 0 3.5rem 0;
      max-width: 60rem;
      @media (max-width: v.$breakpoint-sm) {
        max-width: 50rem;
        padding-top: 2rem;
      }

      // Desktop layout
      @media (min-width: v.$breakpoint-lg) {
        max-width: 70%;
      }
    }
  }

  // link-form
  &__link-form {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 95%;
    transform: translate(-50%, 50%);
  }
  // End of link-form
}
// End of Header
