@use 'variables' as v;

.footer {
  background: v.$text-color;

  .container {
    text-align: center;
    padding: 5rem 0;

    @media (min-width: v.$breakpoint-md) {
      display: grid;
      grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
      text-align: left;
      padding: 7rem 0;
      // background: lightblue;
    }
  }

  &__logo {
    padding-bottom: 4rem;

    @media (min-width: v.$breakpoint-md) {
      padding: 0 0 0 5rem;
    }
  }

  &__icon {
    color: white;
    font-size: 2.5rem;
    transition: color 0.3s;

    &:hover {
      color: v.$primary-color;
    }
  }

  &__link {
    color: white;
    display: block;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    opacity: 0.5;
    transition: color 0.3s;

    &:hover {
      color: v.$primary-color;
      opacity: 1;
    }
  }

  &__section {
    padding: 2rem 0;

    @media (min-width: v.$breakpoint-md) {
      padding: 0 1rem;
    }

    &__heading {
      font-size: 1.7rem;
      color: white;
      padding-bottom: 2rem;
    }

    &-icons {
      display: flex;
      justify-content: space-around;
      padding: 0 2rem;
      padding-top: 2rem;
      @media (min-width: v.$breakpoint-md) {
        padding: 0;
      }
    }
  }
}
// End of Footer
