@use 'variables' as v;

.section-statistics {
  background: v.$very-light-color;

  .container {
    padding-top: 8rem;
    text-align: center;
  }

  &__cards-container {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    // Desktop
    @media (min-width: v.$breakpoint-lg) {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &__bar {
    width: 0.7rem;
    height: 80%;
    background: v.$primary-color;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    // Desktop
    @media (min-width: v.$breakpoint-lg) {
      width: 70%;
      height: 0.7rem;
      left: 50%;
      top: 45%;
      transform: translate(-50%);
    }
  }

  &__card {
    width: 100%;
    background: white;
    position: relative;
    border-radius: 0.5rem;
    padding: 8rem 4rem 4rem 4rem;
    margin-bottom: 10rem;

    // Desktop
    @media (min-width: v.$breakpoint-lg) {
      text-align: left;
      width: 28%;
      height: 25%;
      padding: 8rem 2rem 3rem;

      // For spacing from top
      &-2 {
        margin-top: 8rem;
      }

      // For spacing from top
      &-3 {
        margin-top: 16rem;
      }
    }

    @media (min-width: v.$breakpoint-xl) {
      width: 34rem;
      padding: 8rem 2rem 3rem;
    }

    &__icon-container {
      background: v.$dark-color;
      border-radius: 50%;
      width: 9rem;
      height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      // Desktop
      @media (min-width: v.$breakpoint-lg) {
        left: 25%;
      }

      img {
        width: 50%;
        height: 50%;
        border-radius: 0;
      }
    }

    &__heading {
      font-size: 2.1rem;
      font-weight: 800;
      padding-bottom: 1rem;
    }

    &__text {
      font-size: 1.4rem;
      font-weight: 500;
      color: rgba(v.$light-color, 0.8);
    }
  }

  .m-heading {
    padding: 1.5rem 0;
  }

  .text-sub {
    margin: 0 auto;
    max-width: 50rem;

    @media (min-width: v.$breakpoint-lg) {
      max-width: 50%;
    }
  }
}
